import React from 'react';
import { createTabGroup } from './TabGroup';

export const SchemeTabGroup = createTabGroup(
  {
    Loader: {
      name: 'Loader',
      urlValue: 'loader'
    },
    Codegen: {
      name: 'Codegen',
      urlValue: 'codegen'
    }
  },
  {
    urlKey: 'scheme'
  }
);

export const FrameworkTabGroup = createTabGroup(
  {
    Nextjs: {
      name: 'Next.js',
      urlValue: 'nextjs'
    },
    NextjsAppDir: {
      name: 'Next.js (app dir)',
      urlValue: 'nextjs-app-dir'
    },
    Gatsby: {
      name: 'Gatsby',
      urlValue: 'gatsby'
    },
    Vite: {
      name: 'React (Vite)',
      urlValue: 'vite'
    },
    React: {
      name: 'Plain React',
      urlValue: 'react'
    },
    ReactRouterV5: {
      name: 'React Router v5',
      urlValue: 'react-router-v5'
    },
    ReactRouterV6: {
      name: 'React Router v6',
      urlValue: 'react-router-v6'
    }
  },
  {
    urlKey: 'framework'
  }
);

export const VueVersionTabGroup = createTabGroup(
  {
    Vue3: {
      name: 'Vue 3',
      urlValue: 'vue3'
    },
    Vue2: {
      name: 'Vue 2',
      urlValue: 'vue2'
    }
  },
  {
    urlKey: 'vue_version'
  }
);

export const HttpRequestTabGroup = createTabGroup(
  {
    JavaScript: {
      name: 'JavaScript',
      urlValue: 'js'
    },
    cURL: {
      name: 'cURL',
      urlValue: 'curl'
    }
  },
  {
    urlKey: 'fetch'
  }
);

export function TabGroupsStateProvider({ children }: { children: React.ReactNode }) {
  return (
    <SchemeTabGroup.StateProvider>
      <FrameworkTabGroup.StateProvider>
        <VueVersionTabGroup.StateProvider>
          <HttpRequestTabGroup.StateProvider>{children}</HttpRequestTabGroup.StateProvider>
        </VueVersionTabGroup.StateProvider>
      </FrameworkTabGroup.StateProvider>
    </SchemeTabGroup.StateProvider>
  );
}

export function frameworkSchemeToPackage(framework: React.ComponentType, scheme: React.ComponentType, lib?: string) {
  if (scheme === SchemeTabGroup.Tab.Codegen) {
    if (lib) {
      return `@plasmicapp/react-web/lib/${lib}`;
    } else {
      return '@plasmicapp/react-web/lib/host';
    }
  }
  switch (framework) {
    case FrameworkTabGroup.Tab.Nextjs:
      return '@plasmicapp/loader-nextjs';
    case FrameworkTabGroup.Tab.Gatsby:
      return '@plasmicapp/loader-gatsby';
    case FrameworkTabGroup.Tab.React:
      return '@plasmicapp/loader-react';
  }
}
