import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import React from 'react';

export function Note({
  type,
  title,
  children
}: {
  type?: 'blue' | 'codeWarning' | 'tip' | 'underConstruction' | 'warning';
  title?: string;
  children?: React.ReactNode;
}) {
  return (
    <PlasmicComponent
      component="Note"
      componentProps={{
        className: 'margin-y-medium note',
        children: <div className="plasmic-reset note-content">{children}</div>,
        title,
        withTitle: !!title,
        type
      }}
    />
  );
}
